import axios from 'axios'

export const app = {
  namespaced: true,
  state: {
    loading: false,
    windowWidth: 0,
    windowHeight: 0,
    stickyFooter: false,
    navType: 'logo_language_menu',
    navTitle: '',
    axiosNum: 0,
    goodieBagImageUrl: null,
    goodieBagMobileImageUrl: null,
    coverPhotoUseListUrl: null,
    mobilePhotoUseListUrl: null,
    isCouponItemLoading: true,
    isAddToHomeScreenShowed: false,
    isCookiePolicyShowed: false,
    systemSettings: {
      recaptchaOpen: true,
      emailAddressVerificationCountdown: 120
    },
    homeBanners: [],
    myRewardBanners: [],
    promptBanners: [],
    navIsHomePage: false,
    isHomeAlertShow: false,
    isShowGoodieBagImage: false,
    isShowFooter: true,
    generalAlerts: [],
    isSupportBrowser: true,
    needToShowAlert: false,
    needShowBackToTop: true,
    needShowCookieButton: false,
    wechatAdobeAid: null,
    filterValue: {},
    visualizeFilterValue: {},
    wechatTokenURL: null,
    wechatTokenURLTimeStamp: 0,
    isGrecaptcha: false,
    lastCampaignListState: {},
    lastApplicableStoreState: {},
    lastMyWalletState: {},
    lastGoodiebagCampaignListState: {},
    miniProgramBackTitle: [],
    OneTrustHeight: 0,
    showSentVerificationEmailMessageEndTime: null,
    cleanSearchKey: false
  },
  actions: {
    refreshSystemSettings: async ({ commit, rootState }) => {
      const response = await axios.get('/settings')
      const data = response.data || {}
      console.log('settings', data)
      commit('initSystemSettings', data)
    }
  },
  mutations: {
    startLoading (state) {
      state.loading = true
    },
    showFooter (state) {
      state.isShowFooter = true
    },
    hideFooter (state) {
      console.log('28----       n')
      state.isShowFooter = false
    },
    setGoodieBagImageUrl (state, { goodieBagImageUrl, goodieBagMobileImageUrl, coverPhotoUseListUrl, mobilePhotoUseListUrl }) {
      state.goodieBagImageUrl = goodieBagImageUrl
      state.goodieBagMobileImageUrl = goodieBagMobileImageUrl
      state.coverPhotoUseListUrl = coverPhotoUseListUrl
      state.mobilePhotoUseListUrl = mobilePhotoUseListUrl
    },
    showGoodieBagImageUrl (state) {
      console.log('showGoodieBagImageUrl')
      state.isShowGoodieBagImage = true
    },
    hideGoodieBagImageUrl (state) {
      state.goodieBagImageUrl = null
      state.isShowGoodieBagImage = false
    },
    finishedLoading (state) {
      state.loading = false
    },
    setWindowWidth (state, width) {
      state.windowWidth = width
    },
    setWindowHeight (state, height) {
      state.windowHeight = height
    },
    setStickyFooter (state) {
      state.stickyFooter = true
    },
    unsetStickyFooter (state) {
      state.stickyFooter = false
    },
    setNavType (state, response) {
      state.navType = response
    },
    setNavTitle (state, response) {
      state.navTitle = response
    },
    startAxios (state) {
      state.axiosNum += 1
    },
    finishedAxios (state) {
      state.axiosNum -= 1
    },
    couponItemLoadingFinish (state) {
      state.isCouponItemLoading = false
    },
    addToHomeScreenShowed (state) {
      state.isAddToHomeScreenShowed = true
    },
    initSystemSettings (state, systemSettingsConfig = {}) {
      const { recaptcha_open, email_address_verification_countdown } = systemSettingsConfig
      state.systemSettings = {
        recaptchaOpen: recaptcha_open || true,
        emailAddressVerificationCountdown: email_address_verification_countdown || 120
      }
    },
    initBanners (state, banners = {}) {
      const { homeBanners = [], promptBanners = [], myRewardBanners = [] } = banners
      state.homeBanners = homeBanners
      state.promptBanners = promptBanners
      state.myRewardBanners = myRewardBanners
    },
    initGeneralAlerts (state, generalAlerts) {
      state.generalAlerts = generalAlerts
    },
    cookiePolicyShowed (state) {
      state.isCookiePolicyShowed = true
    },
    setNavIsHomePage (state, response) {
      state.navIsHomePage = response
    },
    setIsHomeAlertShow (state, response) {
      state.isHomeAlertShow = response
    },
    isNotSupportBrowser (state) {
      state.isSupportBrowser = false
    },
    setNeedToShowAlert (state, response) {
      state.needToShowAlert = response
    },
    setNeedShowBackToTop (state, response) {
      state.needShowBackToTop = response
    },
    setNeedShowCookieButton (state, response) {
      state.needShowCookieButton = response
    },
    setWechatAdobeAid (state, response) {
      state.wechatAdobeAid = response
    },
    setWechatTokenURL (state, url) {
      if (state.wechatTokenURLTimeStamp && Date.now() - state.wechatTokenURLTimeStamp < 3000) {
        // the token is set by another page in short time
        return
      }
      url = url.replace(',', '%2C')
      state.wechatTokenURL = url
      state.wechatTokenURLTimeStamp = Date.now()
    },
    setFilterValue (state, { filterName, filterValue }) {
      state.filterValue[filterName] = filterValue
      console.log('@491x', state.filterValue)
    },
    setIsGrecaptcha (state, isGrecaptcha) {
      state.isGrecaptcha = isGrecaptcha
    },
    setlastCampaignListState (state, result) {
      const searchKey = state.lastCampaignListState.searchKey
      state.lastCampaignListState = { ...result, searchKey }
    },
    setlastCampaignListSearchKeyState (state, searchKey) {
      state.lastCampaignListState.searchKey = searchKey
    },
    setLastApplicablesStoreState (state, result) {
      state.lastApplicableStoreState = result
    },
    setLastMyWalletState (state, result) {
      state.lastMyWalletState = result
    },
    setLastGoodiebagCampaignListState (state, result) {
      const searchKey = state.lastGoodiebagCampaignListState.searchKey
      state.lastGoodiebagCampaignListState = { ...result, searchKey }
    },
    setLastGoodiebagCampaignListSearchKeyState (state, searchKey) {
      state.lastGoodiebagCampaignListState.searchKey = searchKey
    },
    setMiniProgramBackTitle (state, title) {
      if (title === 'back') {
        state.miniProgramBackTitle.pop()
      } else {
        state.miniProgramBackTitle.push(title)
      }
    },
    resetMiniProgramBackTitle (state) {
      state.miniProgramBackTitle = []
    },
    setOneTrustHeight (state, result) {
      state.OneTrustHeight = result
    },
    setShowSentVerificationEmailMessageEndTime (state) {
      const showSentVerificationEmailMessageEndTime = new Date()
      showSentVerificationEmailMessageEndTime.setSeconds(showSentVerificationEmailMessageEndTime.getSeconds() + 5)
      state.showSentVerificationEmailMessageEndTime = showSentVerificationEmailMessageEndTime.getTime()
    },
    setCleanSearchKey (state, result) {
      state.cleanSearchKey = result
    }
  },
  getters: {
    isShowFooter: state => {
      return state.isShowFooter
    },
    isMobile: state => {
      return state.windowWidth < 1024
    },
    isHorizontal: state => {
      return state.windowWidth > state.windowHeight
    },
    inMiniProgram: state => {
      return window.__wxjs_environment === 'miniprogram'
    },
    isShowGoodieBagImage: state => {
      console.log('check isShowGoodieBagImage')
      return state.isShowGoodieBagImage
    },
    isSticky: (state, getter) => {
      return state.stickyFooter && getter.isMobile
    },
    getSystemSettings: state => {
      return state.systemSettings
    },
    getHomeBanners: state => {
      return state.homeBanners
    },
    getPromptBanners (state) {
      return state.promptBanners
    },
    getMyRewardBanners (state) {
      return state.myRewardBanners
    },
    getGoodieBagImageUrl (state) {
      return state.goodieBagImageUrl
    },
    getGoodieBagMobileImageUrl (state) {
      return state.goodieBagMobileImageUrl
    },
    getCoverPhotoUseListUrl (state) {
      return state.coverPhotoUseListUrl
    },
    getMobilePhotoUseListUrl (state) {
      return state.mobilePhotoUseListUrl
    },
    getNavType: (state, getter) => {
      return state.navType
    },
    getNavTitle (state, getter) {
      return state.navTitle
    },
    getAxiosNum (state) {
      return state.axiosNum
    },
    getIsCouponItemLoading (state) {
      return state.isCouponItemLoading
    },
    getIsAddToHomeScreenShowed (state) {
      return state.isAddToHomeScreenShowed
    },
    getCookiePolicyShowed (state) {
      return state.isCookiePolicyShowed
    },
    getNavIsHomePage (state) {
      return state.navIsHomePage
    },
    getIsHomeAlertShow (state) {
      return state.isHomeAlertShow
    },
    getIsSupportBrowser (state) {
      return state.isSupportBrowser
    },
    getNeedToShowAlert (state) {
      return state.needToShowAlert
    },
    getNeedShowBackToTop (state) {
      return state.needShowBackToTop
    },
    getNeedShowCookieButton (state) {
      return state.needShowCookieButton
    },
    getWechatAdobeAid (state) {
      return state.wechatAdobeAid
    },
    getWechatTokenURL (state) {
      return state.wechatTokenURL
    },
    getFilterValue: (state) => (filterName) => {
      console.log('@491c', state.filterValue)
      return state.filterValue[filterName]
    },
    getFilterValueAll (state) {
      return state.filterValue
    },
    getIsGrecaptcha (state) {
      return state.isGrecaptcha
    },
    getlastCampaignListState (state) {
      return state.lastCampaignListState
    },
    getLastApplicablestoreState (state) {
      return state.lastApplicableStoreState
    },
    getLastMyWalletState (state) {
      return state.lastMyWalletState
    },
    getLastGoodiebagCampaignListState (state) {
      return state.lastGoodiebagCampaignListState
    },
    getMiniProgramBackTitle (state) {
      if (!state.miniProgramBackTitle.length) {
        return null
      }

      return state.miniProgramBackTitle[state.miniProgramBackTitle.length - 1]
    },
    getIsOneTrustDisplay (state) {
      return state.OneTrustHeight > 0
    },
    getOneTrustHeight (state) {
      return state.OneTrustHeight
    },
    getShowSentVerificationEmailMessageEndTime (state) {
      return state.showSentVerificationEmailMessageEndTime
    },
    getCleanSearchKey (state, result) {
      return state.cleanSearchKey
    }
  }

}

export default app
