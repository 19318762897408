<template>
  <div class="is-flex nav" role="navigation">
    <!-- <div v-if="isMiniProgram()" class="mini-navbar" :class="{'drop': dropdownChange}">
       // temp change 20230619 - walty -->
    <div v-if="isMiniProgram()">
      <div
        v-if="navbarType=='back_language' || navbarType=='back_language_menu'"
        class="mini-back-button"
        @click="back"
      >
        <img
          srcset="/img/toggle.1x.png, /img/toggle.2x.png 2x, /img/toggle.3x.png 3x"
          src="/img/toggle.3x.png"
        >
        <span class="mini-back-button-ellipsis">{{ miniProgramBackTitle }}</span>
      </div>
      <div class="mini-navbar" :class="{'drop': dropdownChange}" :style="isOneTrustDisplay ? 'margin-bottom:'+oneTrustHeight+'px':'margin-bottom:0px'">

        <div class="mini-navbar-col collect" data-aid="home_header_home" @click="mobileMenuItemClick('home')">
          <img
            width="40px"
            srcset="/img/my_collect.1x.png, /img/my_collect.2x.png 2x, /img/my_collect.3x.png 3x"
            src="/img/my_collect.2x.png"
          >
          <div>{{ $t('headerHomeLow') }}</div>
        </div>

        <div class="mini-navbar-col explore" data-aid="home_header_my_wallet" @click="mobileMenuItemClick('explore')">
          <img
            width="40px"
            srcset="/img/explore.1x.png, /img/explore.2x.png 2x, /img/explore.3x.png 3x"
            src="/img/explore.2x.png"
          >
          <div>{{ $t('navBarExplore') }}</div>
        </div>

        <div class="mini-navbar-col wallet" data-aid="home_header_my_wallet" @click="mobileMenuItemClick('my-wallet-all')">
          <img
            width="40px"
            srcset="/img/mini_wallet.1x.png, /img/mini_wallet.2x.png 2x, /img/mini_wallet.3x.png 3x"
            src="/img/mini_wallet.2x.png"
          >
          <div>{{ $t('navBarWallet') }}</div>
        </div>
        <div data-aid="header_my_rewards" class="mini-navbar-col rewards" @click="mobileMenuItemClick('my-reward')">
          <img
            width="40px"
            srcset="/img/mini_rewards.1x.png, /img/mini_rewards.2x.png 2x, /img/mini_rewards.3x.png 3x"
            src="/img/mini_rewards.2x.png"
          >
          <div>{{ $t('navBarRewards') }}</div>
        </div>
        <el-dropdown trigger="click" placement="top-start" @command="handleCommand" @visible-change="handleDropdownChange">
          <div class="el-dropdown-link">
            <el-badge :is-dot="!!this.$store.state.app.generalAlerts.length" class="mini-navbar-col menu">
              <img
                width="40px"
                srcset="/img/mini_menu.1x.png, /img/mini_menu.2x.png 2x, /img/mini_menu.3x.png 3x"
                src="/img/mini_menu.2x.png"
              >
              <div>{{ $t('navBarMenu') }}</div>
            </el-badge>
          </div>
          <el-dropdown-menu slot="dropdown" class="mini-navbar-dropdown-menu" :class="{'has-one-trust': isOneTrustDisplay}">
            <el-dropdown-item v-if="isLoginUser" class="item membership">
              <div>{{ $t('homeMembershipID') }} :</div>
              <div class="id">{{ membershipId }}</div>
            </el-dropdown-item>
            <el-dropdown-item v-else class="item login" command="login">
              <div>{{ $t('wechatLoginSignupNav') }}</div>
            </el-dropdown-item>
            <hr>
            <el-dropdown-item class="item" command="my-account">{{ $t('headerMyAccountLow') }}</el-dropdown-item>
            <el-dropdown-item v-if="this.$store.state.app.generalAlerts.length" class="item" command="alert">
              <el-badge is-dot class="alert">{{ $t('navBarMenuAlert') }}</el-badge>
            </el-dropdown-item>
            <el-dropdown-item class="item" command="policy-tc">{{ $t('miniNavTermsConditions') }}</el-dropdown-item>
            <el-dropdown-item class="item" command="policy-privacy">{{ $t('footerPrivacyPolicy') }}</el-dropdown-item>
            <el-dropdown-item class="item" command="policy-cookie">{{ $t('cookiepolicyCookiePolicy') }}</el-dropdown-item>
            <el-dropdown-item class="item" command="policy-faq">{{ $t('footerFAQ') }}</el-dropdown-item>
            <el-dropdown-item class="item" command="contact">{{ $t('navigationContact') }}</el-dropdown-item>
            <el-dropdown-item class="item" command="language">{{ $t('myAccountLanguagePreference') }}</el-dropdown-item>
            <hr v-if="isLoginUser">
            <el-dropdown-item v-if="isLoginUser" class="item" command="logout">{{ $t('wechatLogout') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </div>

    <div v-if="isShowTopNavBar()" class="is-flex align-items-center between">
      <div class="">
        <div class="navbar-item navbar-brand">
          <img v-show="navbarType=='logo_language_menu' && !isShowMobileLanguage" :src="headerLogo" class="logo-img" @click="goHomePage()">
          <img
            v-show="((navbarType=='back_language' || navbarType=='back_language_menu') && !isShowMobileLanguage && !isShowMobileMenu) || isShowMobileLanguage"
            class="back-img"
            srcset="/img/back.1x.png, /img/back.2x.png 2x, /img/back.3x.png 3x"
            src="/img/back.2x.png"
            @click="back"
          >
          <div v-if="(navbarType=='back_language' || navbarType=='back_language_menu') && this.$store.getters['app/getNavTitle']" class="mobile-page-title">{{ this.$store.getters['app/getNavTitle'] }}</div>
          <div v-else-if="isShowMobileLanguage" class="mobile-page-title">{{ $t('myAccountLanguagePreference') }}</div>
        </div>
      </div>
      <div v-if="!isOnlyShowTopLogo()" class="is-flex navbar-desktop">
        <div v-if="this.$store.state.app.generalAlerts.length" class="navbar-item" data-aid="home_header_alert" @click="showAlert()">
          <img
            class="navbar-home-alert-img"
            width="27px"
            srcset="/img/alert.1x.png, /img/alert.2x.png 2x, /img/alert.3x.png 3x"
            src="/img/alert.1x.png"
          >
        </div>
        <!--<div class="is-flex navbar-item language align-items-center hover-line">
          <img src="/img/vector.png" width="18px" height="18px" class="navbar-item-logo">
          <el-select v-model="lang" :placeholder="language[lang]" @change="changeLanguageIfNeeded()">
            <el-option
              v-for="item in languageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>-->

        <div class="is-flex align-items-center navbar-item has-dropdown is-hoverable language user hover-line">
          <img
            width="19px"
            height="19px"
            class="navbar-item-logo"
            srcset="/img/language.1x.png, /img/language.2x.png 2x, /img/language.3x.png 3x"
            src="/img/language.2x.png"
            @mouseover="sendHomeHeaderLanguage()"
          >
          <span style="text-transform: none;">{{ $t('headerlanguage') }}</span>
          <transition name="slide">
            <div class="navbar-dropdown user-dropdown">
              <div
                v-for="item in languageOptions"
                :key="item.value"
                class="dropdown-item"
                :class="{'selected': item.value==lang}"
                @click="changeLanguageIfNeeded(item.value)"
              >
                {{ item.label }}
              </div>
            </div>
          </transition>
        </div>
        <div class="navbar-item line">
          <el-divider direction="vertical" />
        </div>
        <router-link data-aid="home_header_home" :to="$i18nRoute($route, 'home', null, {})" class="is-flex align-items-center navbar-item navbar-home hover-line">
          <img
            width="30px"
            class="navbar-item-logo "
            srcset="/img/home.1x.png, /img/home.2x.png 2x, /img/home.3x.png 3x"
            src="/img/home.2x.png"
          >
          <div>{{ $t('headerHome') }}</div>
        </router-link>
        <a target="_blank" data-aid="home_header_deals" :href="dealsUrl" class="is-flex align-items-center navbar-item navbar-deals hover-line">
          <img
            width="20px"
            class="navbar-item-logo "
            srcset="/img/deals_desktop.1x.png, /img/deals_desktop.2x.png 2x, /img/deals_desktop.3x.png 3x"
            src="/img/deals_share_desktop.3x.png"
          >
          <div>{{ $t('navDeals') }}</div>
          <div class="deals-share" />
        </a>
        <router-link data-aid="home_header_my_wallet" :to="$i18nRoute($route, 'my-wallet-all', null, {})" class="is-flex align-items-center navbar-item navbar-wallet hover-line">
          <img
            width="18px"
            class="navbar-item-logo my-wallet-logo"
            srcset="/img/my_wallet.1x.png, /img/my_wallet.2x.png 2x, /img/my_wallet.3x.png 3x"
            src="/img/my_wallet.2x.png"
          >
          <div>{{ $t('headerMyWallet') }}</div>
        </router-link>
        <router-link data-aid="header_my_rewards" :to="$i18nRoute($route, 'my-reward', null, {})" class="is-flex align-items-center navbar-item navbar-reward hover-line">
          <img
            width="20px"
            class="navbar-item-logo my-reward-logo"
            srcset="/img/my_reward.1x.png, /img/my_reward.2x.png 2x, /img/my_reward.3x.png 3x"
            src="/img/my_reward.2x.png"
          >
          <div>{{ $t('headerMyRewards') }}</div>
          <div v-if="hasRecentGoodieBag" class="red-dot" />
        </router-link>
        <div v-if="isLoginUser" class="is-flex align-items-center navbar-item has-dropdown is-hoverable user hover-line">
          {{ $t('headerHelloName') }}{{ userName }}
          <transition name="slide">
            <div class="navbar-dropdown user-dropdown">
              <div class="is-flex align-items-center navbar-item account" data-aid="home_header_my_account" @click="pushRouterWithQuery($router, { name: 'my-account' })">
                <img
                  width="30px"
                  class="navbar-item-logo"
                  srcset="/img/my_account.1x.png, /img/my_account.2x.png 2x, /img/my_account.3x.png 3x"
                  src="/img/my_account.2x.png"
                >
                <div>{{ $t('headerMyAccount') }}</div>
              </div>
              <div class="navbar-divider" />
              <div class="navbar-item logout" data-aid="home_header_logout" @click="isShowLogoutConfirm=true">{{ $t('headerLogout') }}
              </div>
            </div>
          </transition>
        </div>
        <div v-else class="is-flex align-items-center navbar-item has-dropdown is-hoverable user hover-line" data-aid="home_header_login" @click="doLogin">
          {{ $t('headerLoginSignup') }}
        </div>
      </div>
      <div v-if="!isOnlyShowTopLogo()" class="is-flex navbar-mobile align-items-center">
        <div class="is-flex align-items-center">
          <!--<div class="is-flex align-items-center navbar-item navbar-language" data-aid="home_header_language" @click="mobileLanguageClick()">
            <img
              width="25px"
              height="25px"
              srcset="/img/my_wallet.1x.png, /img/my_wallet.2x.png 2x, /img/my_wallet.3x.png 3x"
              src="/img/my_wallet.2x.png"
            >
          </div>-->
          <div v-if="!isShowMobileLanguage" class="is-flex align-items-center navbar-item navbar-wallet" data-aid="home_header_my_wallet" @click="mobileMenuItemClick('my-wallet-all')">
            <img
              width="22px"
              srcset="/img/nav_my_wallet.1x.png, /img/nav_my_wallet.2x.png 2x, /img/nav_my_wallet.3x.png 3x"
              src="/img/my_wallet.2x.png"
            >
            <div class="label">{{ $t('headerMyWallet') }}</div>
          </div>
          <div v-if="this.$store.state.app.generalAlerts.length && !isShowMobileLanguage && !isShowMobileMenu" class="is-flex align-items-center navbar-item" data-aid="home_header_alert" @click="showAlert()">
            <img
              style="margin-bottom: 1px;"
              class="navbar-home-alert-img"
              width="36px"
              srcset="/img/alert_mobile.1x.png, /img/alert_mobile.2x.png 2x, /img/alert_mobile.3x.png 3x"
              src="/img/alert_mobile.2x.png"
            >
          </div>
          <a v-if="(navbarType=='logo_language_menu' || navbarType=='back_language_menu') && !isShowMobileLanguage" role="button" class="navbar-burger" :class="{ 'is-active' : isShowMobileMenu }" @click="mobileMenuClick()">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
          <div v-if="navbarType=='back_language'" style="margin-right:20px" />
        </div>
      </div>
    </div>
    <div v-if="isShowMobileMenu" class="navbar-mobile-menu navbar-menu">
      <div class="navbar-start">
        <div v-if="isLoginUser" data-aid="home_header_login" class="is-flex align-items-center navbar-item user">
          {{ $t('headerHelloName') }}{{ userName }}
        </div>
        <div v-else class="is-flex align-items-center navbar-item" @click="doLogin">
          {{ $t('headerLoginSignup') }}
        </div>
        <div class="navbar-divider" />
        <div class="is-flex align-items-center navbar-item home" data-aid="home_header_home" @click="mobileMenuItemClick('home')">
          <img
            style="margin-right: 10px;"
            width="30px"
            class="navbar-item-logo"
            srcset="/img/home.1x.png, /img/home.2x.png 2x, /img/home.3x.png 3x"
            src="/img/home.2x.png"
          >
          <div>{{ $t('headerHomeLow') }}</div>
        </div>
        <div class="is-flex align-items-center navbar-item home" data-aid="home_header_home" @click="mobileMenuItemClick('deals')">
          <img
            style="margin-left: 3px;margin-right: 12px;"
            width="25px"
            class="navbar-item-logo"
            srcset="/img/deals_mobile.1x.png, /img/deals_mobile.2x.png 2x, /img/deals_mobile.3x.png 3x"
            src="/img/deals_mobile.2x.png"
          >
          <div>{{ $t('navDealsLow') }}</div>
          <img
            style="margin-left: 2px;"
            width="25px"
            class="navbar-item-logo"
            srcset="/img/deals_share_mobile.1x.png, /img/deals_share_mobile.2x.png 2x, /img/deals_share_mobile.3x.png 3x"
            src="/img/deals_share_mobile.2x.png"
          >
        </div>
        <div class="is-flex align-items-center navbar-item wallet" data-aid="home_header_my_wallet" @click="mobileMenuItemClick('my-wallet-all')">
          <img
            style="margin-left: 6px;margin-right: 16px;margin-top: 6px;margin-bottom:6px"
            width="18px"
            class="navbar-item-logo"
            srcset="/img/my_wallet.1x.png, /img/my_wallet.2x.png 2x, /img/my_wallet.3x.png 3x"
            src="/img/my_wallet.2x.png"
          >
          <div>{{ $t('headerMyWalletLow') }}</div>
        </div>
        <div data-aid="header_my_rewards" class="is-flex align-items-center navbar-item reward" @click="mobileMenuItemClick('my-reward')">
          <img
            style="margin-left: 5px;margin-right: 16px;margin-top: 5px;margin-bottom:5px"
            width="20px"
            class="navbar-item-logo"
            srcset="/img/my_reward.1x.png, /img/my_reward.2x.png 2x, /img/my_reward.3x.png 3x"
            src="/img/my_reward.2x.png"
          >
          <div>{{ $t('headerMyRewardsSingle') }}</div>
          <div v-if="hasRecentGoodieBag" class="red-dot" />
        </div>
        <div v-if="isLoginUser" data-aid="home_header_my_account" class="is-flex align-items-center navbar-item account" @click="mobileMenuItemClick('my-account')">
          <img
            style="margin-right: 10px;"
            width="30px"
            class="navbar-item-logo"
            srcset="/img/my_account.1x.png, /img/my_account.2x.png 2x, /img/my_account.3x.png 3x"
            src="/img/my_account.2x.png"
          >
          <div>{{ $t('headerMyAccountLow') }}</div>
        </div>
        <div class="navbar-divider" />
        <div class="is-flex align-items-center navbar-item home" @click="mobileMenuItemClick('policy', {type: 'tc'})">
          <img
            style="margin-right: 10px;"
            width="30px"
            class="navbar-item-logo"
            srcset="/img/icn_reward_status.1x.png, /img/icn_reward_status.2x.png 2x, /img/icn_reward_status.3x.png 3x"
            src="/img/icn_reward_status.2x.png"
          >
          <div>{{ $t('footerTermsConditions') }}</div>
        </div>
        <div class="is-flex align-items-center navbar-item home" @click="mobileMenuItemClick('policy', {type: 'privacy'})">
          <img
            style="margin-right: 10px;"
            height="30px"
            class="navbar-item-logo"
            srcset="/img/icon_privacy_policy.1x.png, /img/icon_privacy_policy.2x.png 2x, /img/icon_privacy_policy.3x.png 3x"
            src="/img/icon_privacy_policy.1x.png"
          >
          <div>{{ $t('footerPrivacyPolicy') }}</div>
        </div>
        <div class="is-flex align-items-center navbar-item home" @click="mobileMenuItemClick('policy', {type: 'cookie'})">
          <img
            style="margin-top: 2px;margin-bottom: 2px;margin-left: 2px;margin-right: 12px;"
            width="26px"
            class="navbar-item-logo"
            srcset="/img/cookie.1x.png, /img/cookie.2x.png 2x, /img/cookie.3x.png 3x"
            src="/img/cookie.2x.png"
          >
          <div>{{ $t('generalCookieSuffix') }}</div>
        </div>
        <div class="is-flex align-items-center navbar-item home" @click="mobileMenuItemClick('policy', {type: 'faq'})">
          <img
            style="margin-right: 10px;"
            width="30px"
            class="navbar-item-logo"
            srcset="/img/icn_reward_faq.1x.png, /img/icn_reward_faq.2x.png 2x, /img/icn_reward_faq.3x.png 3x"
            src="/img/icn_reward_faq.2x.png"
          >
          <div>{{ $t('footerFAQ') }}</div>
        </div>
        <div class="is-flex align-items-center navbar-item home" @click="mobileMenuItemClick('contact')">
          <img
            style="margin-top: 2px;margin-bottom: 2px;margin-left: 2px;margin-right: 12px;"
            width="26px"
            class="navbar-item-logo"
            srcset="/img/contact.1x.png, /img/contact.2x.png 2x, /img/contact.3x.png 3x"
            src="/img/contact.2x.png"
          >
          <div>{{ $t('navigationContact') }}</div>
        </div>
        <div class="navbar-divider" />
        <div class="is-flex align-items-center navbar-item language" data-aid="home_header_language" @click="isShowMobileLanguage=true">
          <div class="left">
            <img
              style="margin-top: 6px;margin-bottom: 6px;margin-right: 17px;margin-left: 6px;"
              width="18px"
              height="18px"
              class="navbar-item-logo"
              srcset="/img/language_mobile.1x.png, /img/language_mobile.2x.png 2x, /img/language_mobile.3x.png 3x"
              src="/img/language_mobile.2x.png"
            >
            <div>{{ $t('myAccountLanguagePreference') }}</div>
          </div>
          <div class="right">
            <div>{{ language[lang] }}</div>
            <img
              class="right"
              srcset="/img/more.1x.png, /img/more.2x.png 2x, /img/more.3x.png 3x"
              src="/img/more.2x.png"
            >
          </div>
        </div>
        <div class="navbar-divider" />
        <div v-if="isLoginUser" class="navbar-item logout" data-aid="home_header_logout" @click="isShowLogoutConfirm=true">{{ $t('headerLogoutLow') }}
        </div>
        <!--
        <div class="botton-add-home-screen">
          <div class="botton-add-home-screen-img">
            <img
              width="19px"
              srcset="/img/reminder.1x.png, /img/reminder.2x.png 2x, /img/reminder.3x.png 3x"
              src="/img/reminder.2x.png"
            >
          </div>
          <div>
            {{ $t('generalAddtoHomescreenOfferYoucan') }} <b>{{ $t('generalAddtoHomescreenOfferAdd') }}</b> {{ $t('generalAddtoHomescreenOfferDescription') }} <a @click="mobileMenuItemClick('policy', {type: 'faq'})">{{ $t('generalAddtoHomescreenOfferAffix') }}</a>
          </div>
        </div>
        -->
      </div>
    </div>
    <div v-if="isShowMobileLanguage" class="navbar-mobile-menu navbar-menu">
      <div class="navbar-start">
        <div
          v-for="item in languageOptions"
          :key="item.value"
          class="is-flex align-items-center navbar-item language-item"
          :class="{'selected': item.value == lang}"
          @click="changeLanguageIfNeeded(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="isShowLogoutConfirm"
      :modal="false"
      width="600px"
      top="calc(50vh - 114px)"
      class="logout-dialog"
    >
      <span slot="title">
        <div class="logout-dialog-title">
          {{ $t('generalLogoutPromptTitle') }}
        </div>
      </span>
      <div class="">
        <div class="logout-dialog-message">{{ $t('generalLogoutPromptDescription') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="logout-dialog-cancel secondary-button" @click="isShowLogoutConfirm = false">{{ $t('generalLogoutPromptCancel') }}</el-button>
        <el-button type="primary" class="logout-dialog-confirm primary-button" @click="logoutUser">{{ $t('generalLogoutPromptLogout') }}</el-button>
      </span>
    </el-dialog>
    <div v-if="isShowLogoutConfirm || dropdownChange" class="dialog-modal" />
    <el-dialog
      :visible.sync="showLanguageReminder"
      :modal="false"
      width="316px"
      top="calc(50vh - 114px)"
      class="switch-language-reminder"
    >
      <div class="switch-language-reminder-title">{{ $t('showLanguageReminderTitle', browserLang) }}</div>
      <div class="switch-language-reminder-content">{{ showLanguageReminderContent }}</div>
      <div class="switch-language-reminder-bottom">
        <div class="button primary-button ok" @click="confirmSwichLanguage()">{{ $t('showLanguageReminderOk', browserLang) }}</div>
        <div class="button secondary-button" @click="cancelSwichLanguage()">{{ $t('showLanguageReminderCancel', browserLang) }}</div>
      </div>
    </el-dialog>
    <div v-if="showLanguageReminder" class="dialog-modal language-dialog-modal" />

    <!--
    <select class="hidden-lang-select">
      <option
        v-for="item in languageOptions"
        :key="item.value + '-hidden-lang-select'"
        :value="item.value"
        :selected="item.value === lang"
      >
        {{ item.label }}
      </option>
    </select>
    -->
    <div v-if="isShowTopNavBar()" class="nav-bottom" />
  </div>
</template>

<script>
import { getCampaignDetail, getCampaignlimit } from '../../helpers/http'
import axios from 'axios'
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram, isShowVConsole, pushRouterWithQuery, replaceRouterWithQuery } from '@/utils/utils'
import { loginSSO } from '@/helpers/http'
import { sendManualAnalytics } from '@/utils/tracking'

export default {
  mixins: [utilsMixin],
  props: {
    customGoBack: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      isShowLogoutConfirm: false,
      isShowMobileLanguage: false,
      isShowMobileMenu: false,
      lang: this.$i18n.locale,
      language: {},
      languageOptions: [
        { label: 'English', value: 'en' },
        { label: '繁體中文', value: 'zh-t' },
        { label: '简体中文', value: 'zh-s' }
      ],
      dropdownChange: false,
      showLanguageReminder: false
    }
  },
  computed: {
    headerLogo () {
      const lang = this.$i18n.locale
      if (lang === 'zh-s') {
        return '/img/hktb-logo-sc.svg'
      }

      if (lang === 'zh-t') {
        return '/img/hktb-logo-tc.svg'
      }

      return '/img/hktb-logo.svg'
    },
    navbarType () {
      console.log(this.$store.getters['app/getNavType'])
      return this.$store.getters['app/getNavType'] || 'logo_language_menu'
    },
    isLoginUser () {
      return localStorage.getItem('refreshToken')
    },
    hasGoodieBag () {
      return this.$store.getters['goodiebagparticipationrecords/hasRecord']
    },
    hasRecentGoodieBag () {
      return this.$store.getters['goodiebagparticipationrecords/hasRecentRecord']
    },
    membershipId () {
      let membershipId = ''
      const userProfile = this.$store.getters['auth/userProfile']
      if (userProfile && userProfile.membership_id) {
        membershipId = userProfile.membership_id.toString()
        membershipId = this.insertStr(membershipId, 8, '-')
        membershipId = this.insertStr(membershipId, 4, '-')
      }
      return membershipId
    },
    dealsUrl () {
      const lang = this.$i18n.locale
      if (lang === 'en') {
        return `${process.env.VUE_APP_DEALS_EN_URL}`
      }
      return `${process.env.VUE_APP_DEALS_TC_SC_URL}`
    },
    contactHref () {
      const lang = this.$i18n.locale
      if (lang === 'en') {
        return 'https://www.discoverhongkong.com/eng/contact-us.html'
      } else if (lang === 'zh-s') {
        return 'https://www.discoverhongkong.cn/china/contact-us.html'
      } else {
        return 'https://www.discoverhongkong.com/tc/contact-us.html'
      }
    },
    browserLang () {
      let browserLang = navigator.language || navigator.userLanguage
      console.log('583   @540', browserLang)
      if (['zh', 'zh-CHS', 'zh-Hans', 'zh-CN', 'zh-SG'].indexOf(browserLang) !== -1) {
        browserLang = 'zh-s'
      } else if (['zh-CHT', 'zh-Hant', 'zh-HK', 'zh-TW', 'zh-MO'].indexOf(browserLang) !== -1) {
        browserLang = 'zh-t'
      } else {
        browserLang = 'en'
      }
      return browserLang
    },
    showLanguageReminderContent () {
      // const browser = this.$t('showLanguageReminderContentUI', this.browserLang)
      // let showLanguageReminderContent = this.$t('showLanguageReminderContent', this.browserLang, { UI: browser })
      let showLanguageReminderContent = this.$t('showLanguageReminderContent', this.browserLang)
      if (isWechatMiniProgram()) {
        // showLanguageReminderContent = this.$t('showLanguageReminderContent', this.browserLang, { UI: 'WeChat' })
        showLanguageReminderContent = this.$t('showLanguageReminderContent', this.browserLang)
      }
      return showLanguageReminderContent
    },
    miniProgramBackTitle () {
      let miniProgramBackTitle = this.$t('headerBack')
      if (this.$store.getters['app/getMiniProgramBackTitle']) {
        miniProgramBackTitle = this.$t('headerBack') + this.$store.getters['app/getMiniProgramBackTitle']
      } else if (this.$route.name === 'campaign-detail') {
        miniProgramBackTitle = this.$t('headerBack') + this.$t('toHome')
      }

      return miniProgramBackTitle
    }
  },
  mounted () {
    console.log('@47-- mounted navbar')
    this.$store.commit('app/resetMiniProgramBackTitle')

    const { platform, aaid } = this.$route.query
    const wechatTokenURL = window.location.href.split('#')[0]
    // const wechatTokenURL2 = new URL(this.$route.fullPath, window.location.origin).href
    // window.alert('@528: ' + wechatTokenURL + ', ' + wechatTokenURL2)

    if (isWechatMiniProgram()) {
      if (platform && platform === 'wechat' && aaid) {
        this.$store.commit('app/setWechatAdobeAid', aaid)
      }

      this.$store.commit('app/setWechatTokenURL', wechatTokenURL)
    }

    this.initJs()
    this.initLanguage()

    if (isShowVConsole()) {
      console.log('@667cc, will vconsole', isShowVConsole())

      setTimeout(() => {
        console.log = console.info
        console.log('@667c, start vconsole')
        new window.VConsole()
      }, 1000)
    }

    if (process.env.VUE_APP_QUEUEIT_ENABLED === 'true') {
      window.queueitEnabled = true
    }
    window.$('.hidden-lang-select').change(() => {
      this.changeLanguageIfNeeded(window.$('.hidden-lang-select').val())
    })
    if (this.$route && this.$route.name === 'logout') {
      // logout page is opened as new window, no need to init data again
    } else {
      this.initData()
    }
    this.safeBottom = getComputedStyle(document.documentElement).getPropertyValue('--sab')
    this.safeBottom = '34px'
    // refreshSsoToken()
    const lang = this.$i18n.locale
    console.log('@583 web', lang, 'browser', this.browserLang)
    console.log('@597', document.referrer)
    const isLanguageReminderShown = this.$store.getters['local/getIsLanguageReminderShown']
    console.log('@609 isLanguageReminderShown', isLanguageReminderShown)
    if (this.isMiniProgram() && (lang !== this.browserLang && !isLanguageReminderShown || document.referrer === 'www.discoverhongkong.com')) {
      this.showLanguageReminder = true
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'language_detection_pop_up', 'pv')
    }
    const firstHomeAlertShowed = this.$cookie.get('firstHomeAlertShowed')
    if (!firstHomeAlertShowed) {
      this.$store.commit('app/setIsHomeAlertShow', true)
    }
    this.setBackToTopBottom()
  },
  updated () {
    if (this.isOneTrustDisplay) {
      if (this.$route.name !== 'coupon-detail' && this.$route.name !== 'campaign-detail') {
        if (this.isMiniProgram()) {
          window.$('.back-to-top-bottom').attr('style', 'bottom: calc(' + (75 + this.oneTrustHeight) + 'px + var(--sab)) !important')
        } else {
          window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (20 + this.oneTrustHeight) + 'px !important')
        }
      }
    }
  },
  methods: {
    sendHomeHeaderLanguage () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_language', 'onclick')
    },
    sendHomeHeaderMenu () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_menu', 'onclick')
    },
    handleCommand (command) {
      if (!command) {
        return
      }
      if (command === 'language') {
        this.isShowMobileLanguage = !this.isShowMobileLanguage
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_language', 'onclick')
      } else {
        this.isShowMobileLanguage = false
        if (command.startsWith('policy')) {
          const commandWords = command.split('-')
          this.mobileMenuItemClick('policy', { type: commandWords[1] })
        } else if (command === 'contact') {
          this.mobileMenuItemClick('contact')
        } else if (command === 'login') {
          this.doLogin()
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_login', 'onclick')
        } else if (command === 'logout') {
          this.isShowLogoutConfirm = true
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_logout', 'onclick')
        } else if (command === 'alert') {
          this.showAlert()
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_alert', 'onclick')
        } else if (command === 'my-account') {
          pushRouterWithQuery(this.$router, { name: command })
          // sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_my_account', 'onclick')
        } else {
          pushRouterWithQuery(this.$router, { name: command })
        }
      }
    },
    handleDropdownChange (change) {
      this.sendHomeHeaderMenu()
      this.dropdownChange = change
    },
    insertStr (soure, start, newStr) {
      return soure.slice(0, start) + newStr + soure.slice(start)
    },
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    isOnlyShowTopLogo () {
      // always hide nav bar for mini program
      if (this.$router.history.current.path.includes('verify-email-result')) {
        return true
      }
      return false
      // return true
      /*
      const routerName = this.$route.name
      return !this.isMiniProgram() ||
        (this.isMiniProgram() && (routerName === 'coupon-detail' || routerName === 'campaign-detail'))*/
    },
    isShowTopNavBar () {
      // always hide nav bar for mini program
      return !this.isMiniProgram()
      /*
      const routerName = this.$route.name
      return !this.isMiniProgram() ||
        (this.isMiniProgram() && (routerName === 'coupon-detail' || routerName === 'campaign-detail'))*/
    },
    doLogin () {
      this.isShowMobileMenu = false
      this.isShowMobileLanguage = false
      window.$('html').removeClass('no-scroll')
      loginSSO(null, true)
    },
    initJs () {
      // const lang = this.$i18n.locale
      // let adobePath = process.env.VUE_APP_ADOBE_ANALYTICS_PATH
      // if (lang === 'zh-s' || isWechatMiniProgram()) {
      //   adobePath = process.env.VUE_APP_ADOBE_ANALYTICS_CN_PATH
      // }
      let jsList = []

      if (isWechatMiniProgram()) {
        jsList = [
          // adobePath,
          'https://res.wx.qq.com/open/js/jweixin-1.6.0.js',
          '//static.queue-it.net/script/queueclient.min.js',
          '//static.queue-it.net/script/queueconfigloader.min.js'
        ]
      } else {
        jsList = [
          // adobePath,
          '//static.queue-it.net/script/queueclient.min.js',
          '//static.queue-it.net/script/queueconfigloader.min.js'
          // recaptcha is only for mobile site
          // '//www.recaptcha.net/recaptcha/enterprise.js'
        ]
        if (process.env.VUE_APP_RECACPTCHA_V3_KEY) {
          jsList.push('//www.recaptcha.net/recaptcha/enterprise.js')
        }
      }

      if (isShowVConsole()) {
        jsList.push('//unpkg.com/vconsole@latest/dist/vconsole.min.js')
      }

      for (var i = 0; i < jsList.length; i++) {
        const plugin = document.createElement('script')
        plugin.setAttribute(
          'src',
          jsList[i]
        )
        plugin.setAttribute(
          'type',
          'text/javascript'
        )
        if (jsList[i].includes('queueconfigloader.min.js')) {
          plugin.setAttribute(
            'data-queueit-spa',
            'true'
          )
          plugin.setAttribute(
            'data-queueit-c',
            'hktb'
          )
        }
        console.log('666', jsList[i])
        if (jsList[i].includes('www.recaptcha.net/recaptcha/enterprise.js')) {
          this.$store.commit('app/setIsGrecaptcha', true)
        } else {
          this.$store.commit('app/setIsGrecaptcha', false)
        }
        plugin.async = true
        document.head.appendChild(plugin)
      }
    },
    showAlert () {
      console.log('@234, show alert')
      this.$store.commit('app/setIsHomeAlertShow', true)
    },

    async initData () {
      console.log('@init data, this store', this.$store)

      const ssoUid = this.$store.getters['auth/ssoUid']
      if (ssoUid) {
        this.$store.commit('app/startAxios')
        try {
          await this.$store.dispatch('coupons/refreshCoupons')
        } catch (error) {
          console.log('error--', error)
        }
        this.$store.commit('app/finishedAxios')

        this.$store.commit('app/startAxios')
        try {
          await this.$store.dispatch('goodiebagparticipationrecords/refreshRecords')
        } catch (error) {
          console.log('error--', error)
        }
        this.$store.commit('app/finishedAxios')

        this.$store.commit('app/startAxios')

        const showSentVerificationEmailMessageFomRegister = localStorage.getItem('showSentVerificationEmailMessageFomRegister')
        axios.get(`/customers/${ssoUid}`).then(response => {
          const { data } = response
          console.log('data---- ', data)
          this.$store.commit('auth/setProfile', data)

          /*
          //always use lang of UI
          const lang = this.$i18n.locale
          console.log('@345', data.preferred_message_language, lang, this.$store.state.local.isLanguageChecked)
          if (!this.$store.state.local.isLanguageChecked && lang && data) {
            if (data.preferred_message_language && data.preferred_message_language !== lang) {
            // switch language
              this.$store.commit('local/markLanguageChecked')
              this.changeLanguageIfNeeded(getLangCode(data.preferred_message_language))
            }
          }
          */

          if (data.email_address && !data.email_address_verified) {
            const { sign } = this.$route.query
            if (
              showSentVerificationEmailMessageFomRegister &&
              !(['/my-reward?', '/goodie-bag-campaign-list?'].some(element => window.location.href.includes(element)) &&
                window.location.href.includes('response_code=201')
              ) &&
              !(this.$route && ['my-wallet', 'my-tickets', 'my-coupons', 'my-voucher', 'my-wallet-all', 'my-limit-gift'].includes(this.$route.name) && sign)
              // !(this.$route && ['my-wallet', 'my-tickets', 'my-coupons', 'my-voucher', 'my-wallet-all', 'my-limit-gift', 'my-account'].includes(this.$route.name))
            ) {
              this.showSentVerificationEmailMessage()
            }
          }
          localStorage.removeItem('showSentVerificationEmailMessageFomRegister')
        }).catch(error => {
          console.log('error----', error)
        }).finally(() => {
          this.$store.commit('app/finishedAxios')
        })
      }
      getCampaignlimit(this.$store)
      this.initBanners()
      this.initGeneralAlerts()
      this.initInterestPreferences()

      const { campaignId } = this.$route.query
      console.log('@417', this.$route, campaignId)
      if (campaignId) {
        getCampaignDetail(campaignId)
      }
    },
    initInterestPreferences () {
      axios.get('/interest_preferences?sort=display_priority').then(response => {
        console.log('preferences: ', response)
        const { data } = response
        this.$store.commit('interestpreferences/initInterests', data)
      })
    },
    initGeneralAlerts () {
      axios.get(`/general_alerts?sort=display_priority`).then(response => {
        console.log('response----', response)
        const { data } = response
        this.$store.commit('app/initGeneralAlerts', data)
      }).catch(error => {
        console.log('error--', error)
      })
    },
    async initBanners () {
      try {
        const platform = isWechatMiniProgram() ? 'WECHAT' : 'HOMEPAGE'
        const baseParams = {
          sort: 'display_priority',
          lang: 'zh-t',
          platform
        }

        const homeQueryString = new URLSearchParams({ ...baseParams }).toString()
        const myRewardsQueryString = new URLSearchParams({ ...baseParams, banner_type: 'GOODIE_BAG' }).toString()
        const promptQueryString = new URLSearchParams({ ...baseParams, banner_type: 'GOODIE_BAG_PROMPT' }).toString()
        const {
          0: { data: homeBanners },
          1: { data: myRewardBanners },
          2: { data: promptBanners }
        } = await Promise.all([
          axios.get(`/homepage_banners?${homeQueryString}`),
          axios.get(`/homepage_banners?${myRewardsQueryString}`),
          axios.get(`/homepage_banners?${promptQueryString}`)
        ])
        this.$store.commit('app/initBanners', { homeBanners, myRewardBanners, promptBanners })
      } catch (error) {
        console.log('error--', error)
      }
    },

    mobileMenuItemClick (page, query) {
      window.$('html').removeClass('no-scroll')
      this.isShowMobileMenu = false
      this.isShowMobileLanguage = false

      console.log('@767')
      if (page === 'contact') {
        /*
        // for testing purpose
        this.wechatOpenCamera((res) => {
          console.log('@772', res)
          _this.showCopySuccessWithMessage('SCAN SUCCESS: ' + JSON.stringify(res))
        }, (res) => {
          _this.showCopySuccessWithMessage('SCAN ERROR: ' + JSON.stringify(res))
        })
        */

        const link = this.contactHref
        if (isWechatMiniProgram()) {
          this.$clipboard(link)
          this.showLinkCopySuccess()
        } else {
          window.open(link)
        }
      } else if (page === 'deals') {
        window.open(this.dealsUrl)
      } else {
        pushRouterWithQuery(this.$router, { path: page, query })
      }
    },
    mobileLanguageClick () {
      console.log('mobile change lang')
      this.isShowMobileLanguage = !this.isShowMobileLanguage
      if (this.isShowMobileLanguage) {
        window.$('html').addClass('no-scroll')
      } else {
        window.$('html').removeClass('no-scroll')
      }
      this.isShowMobileMenu = false
    },
    mobileMenuClick () {
      this.isShowMobileMenu = !this.isShowMobileMenu
      if (this.isShowMobileMenu) {
        window.$('html').addClass('no-scroll')
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_header_menu', 'onclick')
      } else {
        window.$('html').removeClass('no-scroll')
      }
      this.isShowMobileLanguage = false
    },
    initLanguage () {
      for (let i = 0; i < this.languageOptions.length; i++) {
        this.language[this.languageOptions[i].value] = this.languageOptions[i].label
      }
    },
    back () {
      if (this.customGoBack) {
        this.customGoBack()
        return
      }

      if (this.isShowMobileLanguage) {
        this.isShowMobileLanguage = false
      } else if (this.$router.history.current.name === 'applicable-store-list' || this.$router.history.current.name === 'applicable-store-detail') {
        if (this.canGoBack()) {
          this.$router.back(-1)
        } else {
          replaceRouterWithQuery(this.$router, { name: 'home' })
        }
      } else if (this.$router.history.current.name === 'coupon-detail') {
        /*
        const query = this.$router.history.current.query
        if (query && query.from) {
          replaceRouterWithQuery(this.$router, { name: query.from })
        } else {
          replaceRouterWithQuery(this.$router, { name: 'my-wallet' })
        }
        */

        // https:// stackoverflow.com/questions/48104842/how-can-i-go-back-route-back-on-vue-router/61433761#61433761
        if (!this.canGoBack()) { // first blank page is one history
          if (this.$store.getters['app/getMiniProgramBackTitle'] && this.$store.getters['app/getMiniProgramBackTitle'] === this.$t('toHome')) {
            replaceRouterWithQuery(this.$router, { name: 'home' })
          } else {
            console.log('@715, no history, go to my wallet')
            replaceRouterWithQuery(this.$router, { name: 'my-wallet' })
          }
        } else {
          this.$router.back(-1)
        }
      } else if (this.$router.history.current.name === 'goodie-bag-campaign-list' || this.$router.history.current.name === 'search-goodie-bag-campaign-list') {
        /*
        const query = this.$router.history.current.query
        if (query && query.from) {
          replaceRouterWithQuery(this.$router, { name: query.from })
        } else {
          replaceRouterWithQuery(this.$router, { name: 'my-reward' })
        }
        */
        const { searchKey } = this.$store.getters['app/getLastGoodiebagCampaignListState']

        console.log('back search key: ', searchKey)
        if (this.$store.getters['app/isMobile'] && searchKey && !isWechatMiniProgram()) {
          // this.$store.commit('app/setLastGoodiebagCampaignListSearchKeyState', '')
          this.$store.commit('app/setCleanSearchKey', true)
          return
        }
        this.$store.commit('app/setCleanSearchKey', false)

        if (!this.canGoBack()) { // first blank page is one history
          console.log('@715, no history, go to my wallet')
          replaceRouterWithQuery(this.$router, { name: 'my-reward' })
        } else {
          this.$router.back(-1)
        }
      } else if (this.$router.history.current.name === 'campaign-detail') {
        // const query = this.$router.history.current.query
        if (this.canGoBack()) {
          this.$router.back(-1)
        } else {
          replaceRouterWithQuery(this.$router, { name: 'home' })
        }
      } else {
        replaceRouterWithQuery(this.$router, { name: 'home' })
      }
      setTimeout(() => { this.$store.commit('app/setMiniProgramBackTitle', 'back') }, 0)
    },
    goHomePage () {
      if (this.$route.name === 'home') {
        window.location.reload()
      } else if (this.isOnlyShowTopLogo()) {
        window.location.href = `${window.location.origin}${this.$router.history.current.path.replace(this.$router.history.current.name, 'home')}`
      } else {
        replaceRouterWithQuery(this.$router, { name: 'home' })
      }
    },
    confirmSwichLanguage () {
      this.changeLanguageIfNeeded(this.browserLang)
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_yes_in_language_detection_pop_up', 'onclick')
      this.showLanguageReminder = false
      this.$store.commit('local/isLanguageReminderShown')
    },
    cancelSwichLanguage () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_no_in_language_detection_pop_up', 'onclick')
      this.showLanguageReminder = false
      this.$store.commit('local/isLanguageReminderShown')
    }
  }
}

</script>
<style scoped lang='scss'>
.mini-navbar{
  padding-bottom: var(--sab);
  height:calc(66px + var(--sab));
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: #FFFFFF;
  z-index: 500;
}
.mini-navbar-col, .nav .drop.mini-navbar .mini-navbar-col{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #000000;
  cursor: pointer;
  img{
    filter: brightness(0);
  }
}

nav .drop.mini-navbar .mini-navbar-col:focus{
  outline: none;
}

.hidden-lang-select {
  width: 1px;
  height: 1px;
  position: absolute;
  opacity: 0;
}

.document-my-wallet-all .navbar-item.hover-line.navbar-wallet{
  color: #E2002C;
}

.document-my-wallet .navbar-item.hover-line.navbar-wallet{
  color: #E2002C;
}

.document-home .navbar-item.hover-line.navbar-home{
  color: #E2002C;
}

.document-my-reward .navbar-item.hover-line.navbar-reward{
  color: #E2002C;
}

.nav{
  height: 65px;
  flex-flow:column;
  width: 100%;

  .navbar-reward {
    position: relative;
    .red-dot {
      width: 7px;
      height: 7px;
      border-radius: 3.5px;
      top: -6px;
      left: 11px;
      background: #FF0000;
      position: relative;
      margin-left: -7px;
    }
  }

  .deals-share{
    margin-left: 8px;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: -webkit-image-set(
      url("/img/deals_share_black_desktop.1x.png") 1x,
      url("/img/deals_share_black_desktop.2x.png") 2x,
      url("/img/deals_share_black_desktop.3x.png") 3x);
    background-image: image-set(
      url("/img/deals_share_black_desktop.1x.png") 1x,
      url("/img/deals_share_black_desktop.2x.png") 2x,
      url("/img/deals_share_black_desktop.3x.png") 3x);
    }

  .language{
    img{
      cursor: pointer;
    }
    .navbar-dropdown{
      background: #FFFFFF;
      width: 167px;
      box-shadow: 0px 2px 9px rgba(224,224,224,67%);
      margin-left: -105px;
      border-top: unset;

      .dropdown-item{
        font-size: 12px;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
      }
      .selected{
        color: #E2002C;
      }
    }
  }

  .navbar-language{
    img{
      cursor: pointer;
    }
  }

  .dialog-modal{
    opacity: 0.5;
    background: #000000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .dialog-modal.language-dialog-modal{
    z-index: 600;
  }

  .logout-dialog-title{
    font-weight: bold;
    font-size: 30px;
    color: #4A4A4A;
  }

  .logout-dialog-message{
    font-size: 15px;
    color: #404040;
    word-break: break-word;
  }

  .logout-dialog-cancel{
    padding: 12px 24px;
    border-radius: 100px;
    height: 51px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
  }

  .logout-dialog-confirm{
    border-radius: 100px;
    padding: 12px 24px;
    height: 51px;
    font-weight: bold;
    font-size: 20px;
    min-width: 134px;
    margin-top: 10px;
  }

  .is-flex.align-items-center.between{
    height: 100%;
  }
  .navbar-dropdown{
    padding: 0px;
  }
  .navbar-item{
    padding: 0;
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 2px;
    padding-left: 30px;
    text-transform:uppercase;
    transition: 0.4s;
  }

  .user .user-dropdown{
    left: unset;
    right: 15px;
    box-shadow: 1px 1px 6px rgba(158, 158, 158, 0.25);
    border-top: unset;
    width: 196px;
    border-radius: 8px;
    transition: 0.4s;
  }

  .language.user .user-dropdown{
    right: 15px;
  }

  .user .user-dropdown .navbar-item:hover{
    background-color: #FA6F8A;
    color: white;
  }
  .user .navbar-item.account{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .user .navbar-item.logout{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .user .user-dropdown .navbar-item:hover img{
    filter: brightness(0) invert(1);
    transition: 0.4s;
  }

  .between{
    justify-content: space-between;
    width: 100%;
  }
  img{
    max-height: unset;
  }
  .logo-img{
    height: 55px;
    width: 136px;
    cursor: pointer;
  }
  .back-img{
    height: 28px;
    width: 28px;
    margin-left: 0px;
    cursor: pointer;
  }
  .navbar-menu{
    flex-grow: unset;
  }

  .navbar-item-logo {
    margin-right: 8px;
  }

  .navbar-item.navbar-home {
    margin-left: 5px;
  }

  .navbar-item.line{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 19px;
  }
  .navbar-item.language{
    padding-right: 0px;
  }
  .navbar-item:focus, .navbar-item:hover{
    background-color: unset;
  }
  .navbar-item.user{
    cursor: pointer;
    text-transform:unset;
    transition: 0.4s;
  }
  .navbar-item.user:hover{
    color: #E2002C;
  }
  .navbar-item.hover-line{
    border-bottom: 5px solid transparent;
    transition: border-bottom 0.8s;
    text-decoration: none;
    padding-left: 17px;
    padding-right: 17px;
    transition: 0.4s;
  }
  .navbar-item.hover-line:hover{
    color: #E2002C;
    .deals-share{
      background-image: -webkit-image-set(
        url("/img/deals_share_red_desktop.1x.png") 1x,
        url("/img/deals_share_red_desktop.2x.png") 2x,
        url("/img/deals_share_red_desktop.3x.png") 3x);
      background-image: image-set(
        url("/img/deals_share_red_desktop.1x.png") 1x,
        url("/img/deals_share_red_desktop.2x.png") 2x,
        url("/img/deals_share_red_desktop.3x.png") 3x);
    }
  }
  .language-item{
    cursor: pointer;
  }

  .align-items-center{
    align-items: center;
  }
  .mobile-page-title{
    font-weight: bold;
    font-size: 18px;
    color: #2D2D2D;
    margin-left: 17px;
    text-transform:none;
  }
  .navbar-home-alert-img{
    cursor: pointer;
  }
}

.is-vue-desktop {
  .nav{
    height: 91px;
  }
  .nav-bottom{
    width: 100%;
    height: 7px;
    background-color: #E2002C;
    flex: none;
  }
  .navbar-mobile{
    display: none !important;
  }
  .navbar-divider{
    margin: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
  .navbar-desktop{
    height: 100%;
    padding-right: 20.5px;
    padding-left: 30px;
  }

  .navbar-item.hover-line{
    padding-top: 5px;
    padding-bottom: 0px;
    height: 100%;
  }

  .user {
    // margin-left: 15px;
    //padding: 0.5rem 0.75rem;
  }

  .user .navbar-item.account{
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
    font-size: 16px;
  }

  .user .navbar-item.logout{
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 19px;
    font-size: 15px;
    font-weight: 600;
  }
  .navbar-mobile-menu{
    display: none;
  }
  .back-img{
    display: none !important;
  }
  .logo-img{
    display: block !important;
  }
  .mobile-page-title{
    display: none;
  }
}

.is-vue-mobile {
  .botton-add-home-screen{
    background: #F7F7F7;
    position: absolute;
    bottom: 0px;
    height: 86px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row;
    align-items: center;
    padding-left: 27px;
    padding-right: 27px;

    .botton-add-home-screen-img{
      margin-right: 9px;
      display: flex;
      flex-flow: column;
      flex: none;
    }
    a{
      text-decoration: underline;
      color: #E7002A;
      font-weight: bold;
      margin-left: 1px;
    }
  }

  .navbar-item.user{
    color: #000000;
  }

  .navbar-wallet, .navbar-reward{
    cursor: pointer;
    flex-flow: column;
    .label{
      font-weight: bold;
      font-size: 9px;
      color: #E2002C;
      margin-top: 3px;
    }

  }

  .mini .navbar-wallet {
    display: none !important;
  }

  .language{
    flex-flow: row;
    justify-content: space-between;
  }
  .language .left{
    display: flex;
    align-items: center;
    img{
      flex: none;
      margin-left: 4px;
      margin-right: 17px;
    }
  }
  .language .right{
    color: #E2002C;
    font-weight: 600;
    margin-right: 20px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    div{
      margin-right: 9px;
    }
    img{
      margin-top: 1px;
    }
  }
  .language-item{
    text-transform: capitalize;
  }
  .language-item.selected{
    color: #E2002C;
  }
  .logout-dialog-title{
    font-size: 20px;
    text-align: center;
  }
  .logout-dialog-message{
    font-size: 14px;
  }
  .logout-dialog-cancel, .logout-dialog-confirm{
    min-width: 113px;
  }
  .nav{
    height: 72px;
  }
  .nav-bottom{
    width: 100%;
    height: 7px;
    background-color: #E2002C;
    flex: none;
  }
  .navbar-desktop{
    display: none !important;

  }

  .navbar-dropdown{
    display: none;
  }

  .navbar-menu{
    display: block !important;
  }

  .navbar-item{
    padding-left: 17px;
    text-transform: capitalize;
  }

  .navbar-mobile-menu .wallet .navbar-item-logo{
    margin-left: 3px;
    margin-right: 16px;
  }

  .navbar-mobile-menu .reward {
    position: relative;
    .red-dot {
      width: 7px;
      height: 7px;
      border-radius: 3.5px;
      top: -6px;
      left: 11px;
      background: #FF0000;
      position: relative;
      margin-left: -7px;
    }
    .navbar-item-logo{
      margin-left: 1px;
      margin-right: 16px;
    }
  }

  .navbar-item.navbar-brand {
    margin-left: 5px;
    padding-left: 15px;
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .navbar-menu{
    padding: 0px;
    position: fixed;
    height: calc(100% - 72px);
    width: 100%;
    top: 72px
  }

  .navbar-mobile-menu {
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(224, 224, 224, 0.67);
    overflow-y: scroll;
  }

  .navbar-mobile-menu .navbar-divider{
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid #E3E3E3;
    height: 1px;
  }

  .navbar-mobile-menu .navbar-item{
    cursor: pointer;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.4s;
  }

  .navbar-mobile-menu .navbar-item.logout{
    font-weight: 600;
    padding-top: 16px;
  }

  .navbar-mobile-menu .navbar-item:hover{
    background-color: #FA6F8A;
    color: white;
    .right{
      color: #FFFFFF;
    }
  }
  .navbar-mobile-menu .navbar-item:hover img{
    filter: brightness(0) invert(1);
    transition: 0.4s;
  }
  .navbar-mobile-menu .navbar-item-logo{
    margin-right: 18px;
  }
  .navbar-burger span:nth-child(1){
    top: calc(50% - 9px);
  }
  .navbar-burger span:nth-child(3){
    top: calc(50% + 7px);
  }
  .navbar-mobile .navbar-burger span{
    height: 2px;
    width: 20px;
  }
  .navbar-mobile .navbar-burger:hover{
    background-color: unset;
  }
  .navbar-mobile .navbar-burger.is-active span:nth-child(1) {
    transform: translateY(7.5px) rotate(45deg);
  }
  .navbar-mobile .navbar-burger.is-active span:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }
}
.el-dropdown-menu.mini-navbar-dropdown-menu{
  border-radius: 12px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  .item{
    font-weight: 600;
    font-size: 15px;
    color: #000000;
    line-height: 45px;
    margin-top: 4px;
    margin-bottom: 4px;
    .id{
      font-weight: 600;
      font-size: 13px;
      color: #999999;
      margin-top: 4px;
    }
  }
  .el-dropdown-menu__item.item:not(.is-disabled):hover{
    background-color: unset;
  }
  .membership.item{
    line-height: 20px;
  }
  hr{
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    height: 1px;
  }
  .login{
    font-size: 18px;
    font-weight: bold;
  }
}
.document-home .mini-navbar-col.collect,
.document-my-reward .mini-navbar-col.rewards,
.document-explore .mini-navbar-col.explore,
.document-my-wallet .mini-navbar-col.wallet,
.document-my-wallet-all .mini-navbar-col.wallet,
.mini-navbar.drop .mini-navbar-col.menu{
  color: #E2002C;
  img{
    filter: none;
  }
}
.mini .nav{
  height: 0px;
}
/*
.document-coupon-detail.mini .nav, .document-campaign-detail.mini .nav{
  height: 72px;
}
*/
.is-vue-mobile .mini .navbar-menu{
  bottom: 66px;
  top: 0px;
  height: calc(100% - 66px);
}
</style>
<style lang='scss'>
.nav{
  .language .el-input__inner{
    border: none;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    padding-left: 0px;
    width: 110px;
  }
  .language .el-input__icon::before{
    color: #000000;
    font-weight: bold;
  }
  .el-divider--vertical{
    height: 25px;
  }
  .logout-dialog .el-dialog{
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  .logout-dialog .el-dialog__header{
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .logout-dialog  .el-dialog__body{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .logout-dialog  .el-dialog__footer{
    padding-right: 30px;
  }
  .logout-dialog .el-dialog__close{
    font-size: 23px;
  }
}
.is-vue-desktop .nav{
  .el-dialog{
    width: 316px !important;
    margin-top: calc(50vh - 150px);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
}
.is-vue-mobile .nav{
  .el-dialog{
    width: 316px !important;
    margin-top: calc(50vh - 150px);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  .el-dialog .account-dialog-title{
    margin-top: 10px;
  }
  .el-dialog__headerbtn{
    display: none;
  }
  .el-dialog__body{
    text-align: center;
  }
  .dialog-footer{
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
  .el-dialog__footer{
    padding-right: 20px;
  }
}
.el-dropdown-menu.mini-navbar-dropdown-menu .popper__arrow{
  display: none;
}
.mini-navbar {
  .el-dropdown .el-dropdown-selfdefine:focus:active, .el-dropdown .el-dropdown-selfdefine:focus:not(.focusing){
    outline: none;
  }
}
.el-badge.mini-navbar-col.menu{
  .el-badge__content.el-badge__content--undefined.is-fixed.is-dot{
    top: 8px;
    right: 8px;
    background-color: #E2002C;
  }
}
.el-badge.alert{
  .el-badge__content.el-badge__content--undefined.is-fixed.is-dot{
    top: 15px;
    right: 2px;
    background-color: #E2002C;
  }
}
//li.el-select-dropdown__item {
//  color: #000000;
//  font-weight: bold;
//  font-size: 12px;
// }
// .el-select-dropdown__item.selected{
//  color: #E2002C;
// }
.switch-language-reminder{
  .switch-language-reminder-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #4A4A4A;
    margin-top: -30px;
  }
  .switch-language-reminder-content{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #404040;
    margin-top: 20px;
  }
  .switch-language-reminder-bottom{
    text-align: center;
    margin: 28px;
    margin-bottom: 0px;
    font-weight: 700;
    line-height: 25px;
    .button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 30px 10px;
      gap:10px;
      border-radius: 50px;
      margin-left: 24.5px;
      margin-right: 24.5px;
      font-size: 18px;
    }
    .ok{
      background-color: #E2002C;
      margin-bottom: 16px;
    }
  }
}

.mini-back-button {
  font-weight: 600;
  font-size: 16px;
  color:#E2002C;
  background-color: #FAFAFA;
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  align-items: center;
  display: flex;
  cursor: pointer;
  img{
    transform: rotate(90deg);
    margin-right: 14px;
  }
}
.mini-back-button .mini-back-button-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.has-one-trust.mini-navbar-dropdown-menu{
  height:60%;
  overflow-y: scroll;
}
.has-one-trust .navbar-start{
  margin-bottom:250px;
}
.register-success-with-email-verification.el-message{
  background-color: #E6F4E7;
  min-width: 228px;
  min-height: 50px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 19px;
  padding-bottom: 15px;
  border: 1px solid rgba(128, 189, 120, 0.1);
  border-radius: 6px;

  strong{
    display: flex;
    align-items: flex-start;
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #4A4A4A;
  }
  strong.content {
    flex-direction: column;
    gap: 4px;
  }
  .icon{
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }
  span {
    //styleName: Mobile_Body 1;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #000000;
  }
}
</style>
