import Vue from 'vue'
import VueRouter from 'vue-router'
import MyWallet from '@/views/MyWallet.vue'
import MyAccount from '@/views/MyAccount.vue'
import FeedBack from '@/views/FeedBack.vue'
import Home from '@/views/Home.vue'
import PreviewTest from '@/views/PreviewTest.vue'
import CouponDetail from '@/views/CouponDetail'
import ExternalPage from '@/views/ExternalPage'
import ApplicableStoreList from '@/views/ApplicableStoreList'
import ApplicableStoreDetail from '@/views/ApplicableStoreDetail'
import PageNotFind from '@/views/PageNotFind'
import WechatLogin from '@/views/WechatLogin'
import Document from '@/components/layout/Document.vue'
import ScanQRCode from '../views/ScanQRCode.vue'
import FortuneCampaign from '@/views/FortuneCampaign.vue'
import GoodieBagDetail from '@/views/GoodieBagDetail.vue'
import GoodieBagCampaignList from '@/views/GoodieBagCampaignList.vue'
import Logout from '@/views/Logout.vue'
import MyReward from '@/views/MyReward.vue'
import VerifyCode from '../views/VerifyCode.vue'
import AcquiresCreditsSuccessPage from '@/views/AcquiresCreditsSuccessPage'
import { i18n } from '../i18n'
// import { getSsoAuthClient } from '../helpers/http'
import { store } from '../store/index'
import { isWechatBrowser } from '@/utils/utils'
import { loginSSO, redirectToH5Wechat } from '@/helpers/http'
import { isWechatMiniProgram, setI18nLocale } from '@/utils/utils'
import { isDev, setupDefaultQuery } from '@/utils/utils'
// import { i18n } from '../localization'

Vue.use(VueRouter)

Vue.prototype.$i18nRoute = (route, name, lang = null, query = null, params = null) => {
  const { query: routeQuery, params: routeParams } = route
  if (!lang) { lang = routeParams.lang }
  if (!query) { query = routeQuery }
  if (!params) {
    params = {}
  }
  params.lang = lang

  query = setupDefaultQuery(routeQuery, query)
  const reuslt = {
    name,
    query,
    params: params
  }

  return reuslt
}

const isValidLang = function (lang) {
  console.log('@16', lang)
  return ['en', 'zh-t', 'zh-s'].includes(lang)
}

const defaultLang = i18n.locale

const routes = [
  {
    path: '/',
    redirect (to) {
      return { path: `/${defaultLang}/home` }
    }
  },
  {
    path: '/verifycode',
    name: 'verifycode',
    component: VerifyCode
  },
  {
    path: '/my-wallet',
    redirect (to) {
      return { path: `/${defaultLang}/my-wallet` }
    }
  },
  {
    path: '/fortune-campaign',
    redirect (to) {
      return { path: `/${defaultLang}/fortune-campaign` }
    }
  },
  {
    path: '/my-account',
    redirect (to) {
      return { path: `/${defaultLang}/my-account` }
    }
  },
  {
    path: '/feedback',
    redirect (to) {
      return { path: `/${defaultLang}/feedback` }
    }
  },
  {
    path: 'applicable-store-list',
    redirect (to) {
      return { path: `/${defaultLang}/applicable-store-list` }
    }
  },
  {
    path: '/:lang',
    component: Document,
    beforeEnter (to, from, next) {
      const preLang = to.params.lang
      console.log('to---1', to)
      const lang = isValidLang(preLang) ? preLang : defaultLang
      if (!isValidLang(preLang)) {
        return next(`/${lang}/page-not-found`)
      }
      // if (!isValidLang(preLang)) return next('/en/home')
      setI18nLocale(lang, i18n)

      return next()
    },
    children: [
      {
        path: 'logout',
        name: 'logout',
        component: Logout

      },
      {
        path: 'home',
        name: 'home',
        component: Home
      },
      {
        path: 'explore',
        name: 'explore',
        component: Home
      },
      {
        path: 'preview-test',
        name: 'Preview-test',
        component: PreviewTest
      },
      {
        path: 'fortune-campaign',
        name: 'fortune-campaign',
        component: FortuneCampaign
      },
      {
        path: 'feedback',
        name: 'feedback',
        meta: {
          requiresAuth: true
        },
        component: FeedBack
      },
      {
        path: 'my-wallet',
        name: 'my-wallet',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyWallet
      },
      {
        path: 'my-tickets',
        name: 'my-tickets',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyWallet
      },
      {
        path: 'my-coupons',
        name: 'my-coupons',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyWallet
      },
      {
        path: 'my-voucher',
        name: 'my-voucher',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyWallet
      },
      {
        path: 'my-wallet-all',
        name: 'my-wallet-all',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyWallet
      },
      {
        path: 'my-limit-gift',
        name: 'my-limit-gift',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyWallet
      },
      {
        path: 'my-account',
        name: 'my-account',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: MyAccount
      },
      {
        path: 'scan-qrcode',
        name: 'scan-qrcode',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ScanQRCode
      },
      {
        path: 'coupon-detail',
        name: 'coupon-detail',
        component: CouponDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'campaign-detail',
        name: 'campaign-detail',
        component: CouponDetail
      },
      {
        path: 'verify-email-result',
        name: 'verify-email-result',
        component: PageNotFind
      },
      {
        path: 'page-not-found',
        name: 'page-not-found',
        component: PageNotFind
      },
      {
        path: 'acquires-credits-success-page',
        name: 'acquires-credits-success-page',
        component: AcquiresCreditsSuccessPage
      },
      {
        path: 'wechat-login',
        name: 'wechat-login',
        component: WechatLogin
      },
      {
        path: 'applicable-store-list',
        name: 'applicable-store-list',
        component: ApplicableStoreList
      },
      {
        path: 'applicable-store-detail',
        name: 'applicable-store-detail',
        component: ApplicableStoreDetail
      },
      {
        path: 'policy',
        name: 'policy',
        component: ExternalPage
      },
      {
        path: 'my-reward',
        name: 'my-reward',
        component: MyReward,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'goodie-bag-detail',
        name: 'goodie-bag-detail',
        component: GoodieBagDetail // landing page no need login
      },
      {
        path: 'goodie-bag-campaign-list',
        name: 'goodie-bag-campaign-list',
        component: GoodieBagCampaignList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'search-goodie-bag-campaign-list',
        name: 'search-goodie-bag-campaign-list',
        component: GoodieBagCampaignList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '*',
        redirect (to) {
          if (isDev()) {
            console.log('@303 Page not found: ' + to.fullPath)
          }

          return { name: 'page-not-found' }
        }
      }
    ]
  },
  {
    path: '*',
    redirect (to) {
      console.log('161--- path: ')
      if (isDev()) {
        console.log('@316 Page not found: ' + to.fullPath)
      }

      return { path: `/${defaultLang}/page-not-found` }
    }
  }
]

let langElementIndex = null
routes.forEach((item, index) => {
  if (item.path === '/:lang') {
    langElementIndex = index
  }
})
if (langElementIndex) {
  if (!process.env.VUE_APP_HIDE_CAMPAIGN_DETAIL === 'true') {
    routes[langElementIndex].children.unshift({
      path: 'campaign-detail',
      name: 'campaign-detail',
      component: CouponDetail,
      redirect (to) {
        return { name: 'home', query: {}}
      }
    })
  }
}

const router = new VueRouter({
  mode: 'history',
  routes
  /* scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 500)
      })
    } else {
      return { top: 0 }
    }
  } */
})

router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem('refreshToken')
  console.log('@350 router change', from, to, window.event,
    window.history.length, router.app.$root.initialHistoryCount)

  const preLang = to.params.lang
  setI18nLocale(preLang, i18n)

  if (isWechatBrowser() && process.env.VUE_APP_WECHAT_H5_URL) {
    redirectToH5Wechat(window.location.origin + to.fullPath)
    return
  }

  /*
  code below will disable the back
  if (window.event && window.event.type === 'popstate') {
    console.log('@350b')
    return next(false)
  }
  */

  console.log('??? route', from, to.name)
  if (
    (from.name === 'goodie-bag-campaign-list' && to.name !== 'campaign-detail') ||
    (from.name === 'explore' && to.name !== 'campaign-detail' && to.name !== 'home') ||
    (from.name === 'home' && to.name !== 'explore')
  ) {
    console.log('??? route remove from store. before enter route')
    if (from.name === 'goodie-bag-campaign-list') {
      const { searchKey } = store.getters['app/getLastGoodiebagCampaignListState']

      console.log('back search key: ', searchKey)
      if (store.getters['app/isMobile'] && searchKey && !isWechatMiniProgram()) {
        // this.$store.commit('app/setLastGoodiebagCampaignListSearchKeyState', '')
        store.commit('app/setCleanSearchKey', true)

        // window.open(from.fullPath, '_self')
        window.history.pushState('', '', from.fullPath.replace('&response_code=201', ''))
        return
      }
      store.commit('app/setCleanSearchKey', false)
    }

    store.commit('app/setLastGoodiebagCampaignListSearchKeyState', '')
    store.commit('app/setlastCampaignListSearchKeyState', '')
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !isLogin) {
    loginSSO(to.fullPath)
    return
  }

  if (to.query && to.query.tester_preview === 'true' && !isLogin) {
    loginSSO(to.fullPath)
    return
  }

  if (to.name === 'campaign-detail' && to.query.recordId && !isLogin) {
    loginSSO(to.fullPath)
    return
  }

  if (to.name === 'campaign-detail' || to.name === 'coupon-detail') {
    router.app.$root.detailNavigated = true
    // console.log('@360', router.app.$root.detailNavigated)
  }

  if (isWechatMiniProgram() && to.name === 'wechat-login') {
    if (!window.wx) {
      alert('Wechat not available!')
    } else {
      console.log('@299', to)

      const { lang } = to.params
      const query = to.query

      const redirectUrl = query ? query.redirectUrl : ''
      let finalUrl = '/pages/login/login?redirect_url=' +
        encodeURIComponent(redirectUrl) + '&lang=' + encodeURIComponent(lang)
      if (query.hgb) {
        console.log('@49', finalUrl)
        finalUrl += '&hgb=1' // hide go home button
      }
      window.wx.miniProgram.navigateTo({ url: finalUrl })
    }
    return
  }

  if (from.name === 'coupon-detail' && to.name === 'my-wallet') {
    console.log('set query')
    store.commit('coupons/setNeedInitQuery', false)
  } else {
    store.commit('coupons/setNeedInitQuery', true)
  }
  return next()
})

export default router
